<template>
  <Select v-bind="$attrs" :value="value" @on-change="value => $emit('input', value)">
    <Option v-for="item in stockList" :key="item.id" :value="item.id">
      {{ item.storehouseName }} / {{ item.storehouseUnitName }} /
      {{ item.volume }} {{ item.goodsUnit }}
    </Option>
  </Select>
</template>

<script>
import request from '../../../api'
export default {
  inheritAttrs: false,
  props: {
    goodsId: { type: Number, default: null },
    value: { type: Number, default: null }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      stockList: [],
      loading: {
        search: false
      }
    }
  },
  watch: {
    goodsId () {
      this.loadStockList()
    }
  },
  methods: {
    async loadStockList () {
      if (!this.goodsId) {
        this.stockList = []
      } else {
        this.stockList = await request.get('/goods_stock', {
          goodsId: this.goodsId
        })
      }
    }
  },
  mounted () {
    this.loadStockList()
  }
}
</script>