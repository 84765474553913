<style lang="less" scoped>
  .base-page {
    margin: 10px;
    padding: 10px;
    background-color: #FFF;
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
  }
  .content {
    flex: 1;
    height: 0;
    overflow: auto;
    position: relative;
  }
</style>

<template>
  <div class="base-page">
    <slot name="header"></slot>
    <div ref="content" class="content">
      <slot v-bind:contentHeight="contentHeight"></slot>
    </div>
    <Page style="margin-top: 10px;" :simple="simple" :current="page" @on-change="page => $emit('on-page-change', page)" v-if="page !== null" :total="total" :page-size="limit" show-total show-elevator />
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: null
    },
    limit: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      contentHeight: 0,
      rows: 0
    }
  },
  methods: {
    onResize () {
      this.$nextTick(() => {
        this.contentHeight = this.$refs.content.offsetHeight
        const rows = Math.max(Math.ceil((this.contentHeight - 48) / 48), 1)
        if (rows !== this.rows) {
          this.rows = rows
          this.$timer && clearTimeout(this.$timer)
          this.$timer = setTimeout(() => {
            this.contentHeight = this.$refs.content.offsetHeight
            this.$emit('on-rows-change', this.rows)
          }, 300)
        }
      })
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
