<style lang="less" scoped>
  .goods-select {
    display: flex;
    flex-direction: column;
  }
  .select-list {
    flex: 1;
    height: 0;
    overflow: auto;
    .item {
      padding: 5px 10px;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        background-color: rgba(47, 91, 234, .1);
      }
      &:hover, &.active {
        color: #2F5BEA;
      }
    }
  }
  .types {
    padding: 0 10px;
    overflow-x: auto;
    white-space: nowrap;
    .item {
      display: inline-block;
      white-space: nowrap;
      font-weight: bold;
      cursor: pointer;
      & + .item {
        margin-left: 5px;
        &::before {
          margin-right: 5px;
          content: '';
          height: 10px;
          width: 1px;
          display: inline-block;
          background-color: #ccc;
        }
      }
      transition: all .3s;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 50%;
        opacity: 0;
        transition: all .3s;
        background-color: #2F5BEA;
        display: inline-block;
        transform: translateX(-50%);
        left: calc(50% + 2px);
      }
      &:hover, &.active {
        color: #2F5BEA;
      }
      &.active::after {
        opacity: 1;
      }
    }
  }
  .search {
    margin: 10px;
  }
</style>

<template>
  <div class="goods-select">
    <div class="scrollbar smallbar types">
      <div class="item" :class="{ active: item.value === useType }" @click="setType(item.value)" v-for="item in scmGoodsUseTypeList" :key="item.value">{{ item.label }}</div>
    </div>
    <div class="search">
      <Input placeholder="名称搜索" v-model="searchKey" clearable @on-blur="onQueryChange" search @on-search="onQueryChange" @on-clear="onQueryChange" />
    </div>
    <div class="select-list">
      <template v-if="defaultItem">
        <div class="item" :class="{ active: goodsId === defaultItem.id }" v-show="defaultItem && !query" :value="defaultItem.id">{{ defaultItem.name + ' - ' + defaultItem.ggxh }}</div>
      </template>
      <div class="item" :class="{ active: goodsId === item.id }" @click="onValueChange(item)" v-for="item in goodsList" :key="item.id" :value="item.id">{{ item.name + ' - ' + item.ggxh }}</div>
    </div>
  </div>
</template>

<script>
import request from '../../../api'
export default {
  props: {
    value: { type: Number, default: null }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      useType: 1,
      goodsId: null,
      loading: false,
      goodsList: [],
      defaultItem: null,
      searchKey: null,
      query: null
    }
  },
  computed: {
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
  },
  watch: {
    value (val) {
      if (val !== this.goodsId) {
        this.loadData()
      }
    }
  },
  methods: {
    setType (type) {
      this.useType = type
      this.onUseTypeChange()
    },
    onValueChange (v) {
      this.$emit('select', v)
      if (v.id !== this.value) {
        this.$emit('input', v.id)
      }
    },
    async onUseTypeChange () {
      this.loading = true
      this.$baseList = await request.get('/goods', {
        useType: this.useType
      })
      const value = this.value || this.goodsId
      this.defaultItem = value ? this.$baseList.find(v => v.id === value) : null
      const goodsList = this.$baseList.slice(0, 200)
      this.goodsList = this.defaultItem ? goodsList.filter(v => v.id !== value) : goodsList
      this.loading = false
    },
    onQueryChange () {
      if (this.searchKey !== this.query) {
        const query = this.query = this.searchKey
        let goodsList = []
        if (query) {
          goodsList = this.$baseList.filter(v => {
            return (v.name || '').indexOf(query) > -1 || (v.ggxh || '').indexOf(query) > -1
          }).slice(0, 200)
        } else {
          goodsList = this.$baseList.slice(0, 200)
        }
        const value = this.value || this.goodsId
        this.goodsList = value ? goodsList.filter(v => v.id !== value) : goodsList
      }
    },
    async loadData () {
      if (this.value) {
        this.loading = true
        const data = await request.get('/goods', { id: this.value })
        this.loading = false
        if (data.length) {
          this.useType = data[0].useType
          await this.onUseTypeChange()
          this.$nextTick(() => {
            this.goodsId = this.value
          })
        }
      } else {
        this.goodsId = null
        await this.onUseTypeChange()
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
