<style scoped></style>

<template>
  <div style="height: 100%;display: flex;flex-direction: column;">
    <slot name="header"></slot>
    <div style="flex: 1;height: 0;overflow: auto;">
      <slot></slot>
    </div>
    <slot name="footer"></slot>
    <Row style="margin-top: 10px;" type="flex">
      <i-col style="flex: 1;">
        <Button :loading="loading" long @click="$emit('cancel')">{{ cancelText }}</Button>
      </i-col>
      <i-col style="flex: 1;margin-left: 10px;">
        <Button :loading="loading" long @click="$emit('confirm')" type="primary">{{ okText }}</Button>
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
    okText: { type: String, default: '确定' },
    cancelText: { type: String, default: '取消' }
  }
}
</script>
