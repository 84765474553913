<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="货物" prop="goodsId">
      <GoodsChoose v-model="formData.goodsId" />
    </FormItem>
    <FormItem label="库存" v-if="formData.goodsId" prop="goodsStockId">
      <GoodsStockChoose :goods-id="formData.goodsId" v-model="formData.goodsStockId" placeholder="请选择库存" />
    </FormItem>
    <FormItem label="计划处置量" prop="planVolume">
      <InputNumber controls-outside v-model="formData.planVolume" />
    </FormItem>
    <FormItem label="是否切换为其他物品">
      <i-switch v-model="isSwitch" />
    </FormItem>
    <FormItem label="计划转换物品" prop="switchGoodsId" v-if="isSwitch">
      <GoodsChoose v-model="formData.switchGoodsId" />
    </FormItem>
    <FormItem label="计划转换物品数量" prop="planSwitchGoodsVolume" v-if="isSwitch">
      <InputNumber controls-outside v-model="formData.planSwitchGoodsVolume" />
    </FormItem>
    <FormItem label="计划售卖金额" prop="planSellAmount">
      <InputNumber controls-outside v-model="formData.planSellAmount" />
    </FormItem>
    <FormItem label="备注" prop="remark">
      <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
import GoodsChoose from '../../goods/base/choose.vue'
import GoodsStockChoose from '../../stock/choose/goods.vue'

// sellAmount
// dealUserId

export function getDefault () {
  return {
    id: null,
    goodsId: null,
    goodsStockId: null,
    planVolume: null,
    switchGoodsId: null,
    planSwitchGoodsVolume: null,
    planSellAmount: null,
    remark: null
  }
}

export default {
  components: { GoodsChoose, GoodsStockChoose },
  props: {
    data: { type: Object, default: null }
  },
  data () {
    const validator = (rule, value, callback) => {
      if (this.isSwitch && value === null || value === undefined) {
        callback(new Error('不能为空'))
      } else {
        callback()
      }
    }

    const rules = Object.freeze({
      goodsId: [
        { required: true, message: '请选择货物' }
      ],
      goodsStockId: [
        { required: true, message: '请选择库存' }
      ],
      planVolume: [
        { required: true, message: '请输入计划量' }
      ],
      planSellAmount: [
        { required: true, message: '请输入计划售卖金额' }
      ],
      switchGoodsId: [
        { validator }
      ],
      planSwitchGoodsVolume: [
        { validator }
      ],
    })
    
    return {
      isSwitch: this.data && this.data.switchGoodsId ? true : false,
      formData: JSON.parse(JSON.stringify(this.data)) || getDefault(),
      rules
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = JSON.parse(JSON.stringify(this.data)) ? JSON.parse(JSON.stringify(this.data)) : getDefault()
        this.isSwitch = this.data && this.data.switchGoodsId ? true : false
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const data = JSON.parse(JSON.stringify(this.formData))
            if (!this.isSwitch) {
              delete data.goodsId
              data.switchGoodsId = null
              data.planSwitchGoodsVolume = null
            }
            resolve(data)
          } else {
            resolve(false)
          }
        })
      })
    }
  }
}
</script>
